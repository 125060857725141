@font-face {
  font-family: 'clash-display';
  src: url('./resources//fonts/ClashDisplay-Extralight.woff2') format('woff2'),
    url('./resources//fonts/ClashDisplay-Extralight.woff') format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'clash-display';
  src: url('./resources//fonts/ClashDisplay-Light.woff2') format('woff2'),
    url('./resources//fonts/ClashDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'clash-display';
  src: url('./resources//fonts/ClashDisplay-Regular.woff2') format('woff2'),
    url('./resources//fonts/ClashDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'clash-display';
  src: url('./resources//fonts/ClashDisplay-Medium.woff2') format('woff2'),
    url('./resources//fonts/ClashDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'clash-display';
  src: url('./resources//fonts/ClashDisplay-Semibold.woff2') format('woff2'),
    url('./resources//fonts/ClashDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'clash-display';
  src: url('./resources//fonts/ClashDisplay-Bold.woff2') format('woff2'),
    url('./resources//fonts/ClashDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

.blinking-cursor {
  /* font-weight: 100;
  font-size: 30px;
  color: #2e3d48; */
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blog.active {
  color: #8b7afc;
  font-weight: 600;
}

.p-calendar {
  /* width: 100% !important; */
}

.p-calendar .p-inputtext {
  width: 100% !important;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #282625;
  color: #ffffff;
}

.p-calendar .p-inputtext:focus {
  box-shadow: none;
}

.p-datepicker .p-datepicker-header,
.p-datepicker:not(.p-datepicker-inline) {
  background: #8c7bff;
  position: relative;
}

.p-datepicker .p-datepicker-header {
  border-bottom: 1px solid #8c7bff;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  color: white;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: white;
}

.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: #8c7bff;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #8c7bff;
}

.p-datepicker table td > span {
  color: black;
}

.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  color: #8c7bff;
  background-color: rgba(140, 123, 255, 0.1);
}

.p-datepicker table td > span.p-highlight {
  color: #8c7bff;
  background-color: rgba(140, 123, 255, 0.1);
}

.p-datepicker table th > span {
  color: black;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  color: white;
}

.p-datepicker table td.p-datepicker-today > span {
  color: white;
  background: #8c7bff;
}

.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background-color: white;
  color: #8c7bff;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
  color: #fff;
}

.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  color: #8c7bff;
  background-color: white;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  color: white;
}

.p-datepicker {
  z-index: 1301 !important;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: none;
  /* padding: 0; */
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:nth-child(2) {
  border-left: 1px solid rgba(0, 0, 0, 1);
  /* padding: 0; */
}
.p-datepicker-multiple-month .p-datepicker-group-container {
  /* flex-direction: column; */
}
.p-datepicker table td {
  /* padding: 0; */
}
.p-datepicker td > span {
  margin: 4px;
}
.p-datepicker th > span {
  margin: 0;
}
.p-calendar .p-datepicker {
  min-width: auto;
}
@media (max-width: 1000px) {
  /* .p-datepicker table td > span {
    width: 1.5rem;
    height: 1.5rem;
  } */
  .p-datepicker-multiple-month .p-datepicker-group-container {
    flex-direction: column;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:nth-child(2) {
    border-left: none;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    /* border-left: none; */
    padding: 0;
  }
}
@media (max-width: 576px) {
  .css-jtcya9 div input {
    width: 30px !important;
    height: 30px !important;
  }

  .css-h5fkc8 a {
    font-size: 18px !important;
  }
  .p-datepicker table td > span {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-link {
    font-size: 0.8rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    padding: 0;
  }
}

.react-player > video {
  border-radius: 14px;
}

.react-player {
  width: 100% !important;
}

.player {
  height: auto !important;
  width: 350px !important;
}

.ck-editor__editable_inline {
  min-height: 400px;
}

.show-more-less-clickable {
  color: #8C7BFF;
  font-weight: 600;
  cursor: pointer;
}